*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $babyPowder;
    outline: none;
}

a{
    color: $babyPowder;
    transition: all ease 0.5s;

    &:hover{
        color: $cadetGray;
        text-decoration: none;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $darkGunmetal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}